import fetch from '@/utils/fetch'
export function getAccusationTarget(params) {
    return fetch({
      url: '/api/org/listOrg',
      method: 'GET',
      params
    })
  }
  export function getWxInfo(params) {
    return fetch({
      url: '/user/getWxInfo?code='+params,
      method: 'GET',
      params
    })
  }
  export function postAddAccusation(params) {
    return fetch({
        url: '/api/accusation/addAccusation',
        method: 'POST',
        headers: {
            'Content-Type': 'multipart/form-data'
            },
            data:params
      })
  }
