import axios from 'axios'


const server = axios.create({
  //baseURL: "http://site.hzgx.info:9043",
  //baseURL: "http://192.168.12.223",
  baseURL: "http://47.98.137.76:9043",
  timeout: 20000,
  responseType: 'json',
  withCredentials: false // 允许携带cookie
})

// 请求拦截器
server.interceptors.request.use(
  config => {

    
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 相应拦截
server.interceptors.response.use(
  response => {
    const res = response && response.data
 
      return res
    
  },
  error => {
    console.log(error)
    //Toast(error.message)
    return Promise.reject(error)
  }
)

export default server