<template >
  <form>
    <div class="weui-form">
      <div class="js_dialog" v-show="msgShow">
        <div class="weui-mask"></div>
        <div class="weui-dialog">
          <div class="weui-dialog__bd">{{ msg }}</div>
          <div class="weui-dialog__ft">
            <a
              @click="msgClose()"
              class="weui-dialog__btn weui-dialog__btn_primary"
              >知道了</a
            >
          </div>
        </div>
      </div>
      <div class="weui-flex">
        <div class="weui-flex__item"><div class="placeholder"></div></div>
        <div class="weui-flex__item">
          <div class="placeholder">
            <ul class="weui-select__files">
              <li
                class="weui-uploader__file weui-uploader__file_status"
                style="background-image: url(./static/images/1.png)"
                v-if="type == 0"
              >
                <div class="weui-uploader__file-content">
                  <i class="weui-icon-success weui-icon_msg"></i>
                </div>
              </li>
              <li
                class="weui-uploader__file"
                style="background-image: url(./static/images/1.png)"
                v-else
                @click="typeClick(0)"
              >
                <div class="weui-uploader__file-content"></div>
              </li>
            </ul>
          </div>
        </div>
        <div class="weui-flex__item">
          <div class="placeholder">
            </div>
            </div>
        <div class="weui-flex__item">
          <div class="placeholder">
            <ul class="weui-select__files">
              <li
                class="weui-uploader__file weui-uploader__file_status"
                style="background-image: url(./static/images/2.png;"
                v-if="type == 1"
              >
                <div class="weui-uploader__file-content">
                  <i class="weui-icon-success weui-icon_msg"></i>
                </div>
              </li>
              <li
                class="weui-uploader__file"
                style="background-image: url(./static/images/2.png;"
                v-else
                @click="typeClick(1)"
              >
                <div class="weui-uploader__file-content"></div>
              </li>
            </ul>
          </div>
        </div>
        <div class="weui-flex__item"><div class="placeholder">
          </div>
          </div>
      </div>

      <div class="weui-form__control-area">
        <div class="weui-cells__group weui-cells__group_form">
          <div class="weui-cells__title">填写信息</div>
          <div class="weui-cells weui-cells_form">
            <div class="weui-cell weui-cell_active weui-cell_switch">
              <div class="weui-cell__bd">保密</div>
              <div class="weui-cell__ft">
                <label for="switchCP" class="weui-switch-cp">
                  <input
                    id="switchCP"
                    class="weui-switch-cp__input"
                    type="checkbox"
                    checked="checked"
                    v-model="isSecret"
                  />
                  <div class="weui-switch-cp__box"></div>
                </label>
              </div>
            </div>
            <div class="weui-cell weui-cell_active">
              <div class="weui-cell__hd">
                <label class="weui-label">姓名</label>
              </div>
              <div class="weui-cell__bd">
                <input
                  v-model="name"
                  class="weui-input"
                  placeholder="请填写姓名"
                  maxlength="16"
                />
              </div>
            </div>
            <div class="weui-cell weui-cell_active">
              <div class="weui-cell__hd">
                <label class="weui-label">手机号码</label>
              </div>
              <div class="weui-cell__bd">
                <input
                  v-model="mobile"
                  class="weui-input"
                  placeholder="请填写手机号码"
                  type="number"
                  pattern="[0-9]*"
                  maxlength="11"
                />
              </div>
            </div>

            <div class="weui-cell weui-cell_active">
              <div class="weui-cell__hd">
                <label class="weui-label">邮箱</label>
              </div>
              <div class="weui-cell__bd">
                <input
                  v-model="mail"
                  class="weui-input"
                  placeholder="请输入邮箱"
                  maxlength="64"
                />
              </div>
            </div>

            <div class="weui-cell weui-cell_active">
              <div class="weui-cell__hd">
                <label class="weui-label">地址</label>
              </div>
              <div class="weui-cell__bd">
                <input
                  v-model="location"
                  class="weui-input"
                  placeholder="请填写联系地址"
                  maxlength="128"
                />
              </div>
            </div>

            <div class="weui-cell weui-cell_active">
              <div class="weui-cell__hd">
                <label class="weui-label">投诉对象</label>
              </div>
              <div class="weui-cell__bd">
                <input
                  v-model="accusationTarget"
                  class="weui-input"
                  placeholder="请填写投诉对象"
                  maxlength="64"
                />
              </div>
            </div>

            <div class="weui-cell weui-cell_active">
              <div class="weui-cell__hd">
                <label class="weui-label">标题</label>
              </div>
              <div class="weui-cell__bd">
                <input
                  v-model="title"
                  class="weui-input"
                  placeholder="请填写标题"
                  maxlength="64"
                />
              </div>
            </div>

            <div class="weui-cells__group weui-cells__group_form">
              <div class="weui-cells__title">投诉内容</div>
              <div class="weui-cells weui-cells_form">
                <div class="weui-cell">
                  <div class="weui-cell__bd">
                    <textarea
                      class="weui-textarea"
                      placeholder="请输入投诉内容"
                      rows="3"
                      v-model="content"
                      maxlength="500"
                    ></textarea>
                    <div class="weui-textarea-counter">
                      <span>{{ content.length }}</span
                      >/500
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="weui-cells weui-cells_form">
              <div class="weui-cell weui-cell_uploader">
                <div class="weui-cell__bd">
                  <div class="weui-uploader">
                    <div class="weui-uploader__hd">
                      <p class="weui-uploader__title">图片上传</p>
                      <div class="weui-uploader__info">1/1</div>
                    </div>
                    <div class="weui-uploader__bd">
                      <ul class="weui-uploader__files" id="uploaderFiles">
                        <li
                          class="weui-uploader__file"
                          :style="imgUrl"
                          v-show="imgUrl != ''"
                        ></li>
                      </ul>
                      <div class="weui-uploader__input-box">
                        <input
                          @change="tirggerFile($event)"
                          class="weui-uploader__input"
                          type="file"
                          accept="image/*"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="weui-cell weui-cell_active weui-cell_switch">
              <div class="weui-cell__bd">是否公开</div>
              <div class="weui-cell__ft">
                <label for="switchCP1" class="weui-switch-cp">
                  <input
                    id="switchCP1"
                    class="weui-switch-cp__input"
                    type="checkbox"
                    v-model="isPublic"
                  />
                  <div class="weui-switch-cp__box"></div>
                </label>
              </div>
            </div>

            <div class="weui-cell weui-cell_active weui-cell_vcode">
              <div class="weui-cell__hd">
                <label class="weui-label">验证码</label>
              </div>
              <div class="weui-cell__bd">
                <input
                  autofocus
                  class="weui-input"
                  type="text"
                  pattern="[0-9]*"
                  v-model="vCode"
                  placeholder="输入验证码"
                  maxlength="2"
                />
              </div>
              <div class="weui-cell__ft">
                <button class="weui-btn weui-btn_default weui-vcode-btn">
                  {{ vCodeString }}
                </button>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <div class="weui-form__tips-area">
        <p class="weui-form__tips"></p>
      </div>
      <div class="weui-form__opr-area">
        <a class="weui-btn weui-btn_primary" @click="submit($event)">提 交</a>
      </div>
    </div>
  </form>
</template>

<script type="text/ecmascript-6">
import {
  getAccusationTarget,
  postAddAccusation,
  getWxInfo,
} from "@/api/report";
export default {
  components: {},
  props: {},
  data() {
    return {
      code: "",
      openid: "o7xE81SvOFOPEj2E64RoELXzI1fU",
      name: "",
      mobile: "",
      mail: "",
      location: "",
      accusationTarget: "",
      title: "",
      content: "",
      isSecret: false,
      isPublic: false,
      type: 0,
      file: null,
      vCode: "",
      vCodeString: "1+1-?",
      msg: "错误信息",
      msgShow: false,
      accusationTargetList: [],
      random: 0,
      imgUrl: "",
      comment: "test",
    };
  },
  watch: {},
  computed: {},
  methods: {
    submit: function (event) {
      event.preventDefault(); //取消默认行为

      if (this.name.length == 0) this.msgDialog("请输入姓名");
      else if (this.mobile.length == 0) this.msgDialog("请输入手机号码");
      else if (this.location.length == 0) this.msgDialog("请输入地址");
      else if (this.title.length == 0) this.msgDialog("请输入标题");
      else if (this.content.length == 0) this.msgDialog("请输入内容");
      else if (this.name.length >= 10) this.msgDialog("姓名过长");
      else if (this.mobile.length > 11) this.msgDialog("请检查手机号码");
      else if (this.location.length > 255) this.msgDialog("地址过长");
      else if (this.title.length > 20) this.msgDialog("标题过长");
      else if (this.content.length > 500) this.msgDialog("内容过长");
      else {
        //创建 formData 对象
        let formData = new FormData();
        // 向 formData 对象中添加文件
        if (this.file != null) formData.append("file", this.file);
        formData.append("name", this.name);
        formData.append("mobile", this.mobile);
        formData.append("mail", this.mail);
        formData.append("location", this.location);
        formData.append("accusationTarget", this.accusationTarget);
        formData.append("title", this.title);
        formData.append("content", this.content);
        formData.append("isSecret", this.isSecret ? 1 : -1);
        formData.append("isPublic", this.isPublic ? 1 : -1);
        formData.append("comment", this.comment);
        formData.append("type", this.type);
        formData.append("openid", this.openid);
        this.postAddAccusation(formData);
      }
    },
    tirggerFile: function (event) {
      this.file = event.target.files[0];
      let url = "";
      let that = this;
      var reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = function () {
        url = this.result.substring(this.result.indexOf(",") + 1);
        that.imgUrl =
          "background-image: url(data:image/png;base64," + url + ")";
      };
    },
    typeClick(sel) {
      this.type = sel;
    },
    msgClose() {
      this.msgShow = false;
    },
    getAccusationTarget() {
      getAccusationTarget().then((res) => {
        this.accusationTargetList = res.data;
      });
    },
    getWxInfo(code) {
      getWxInfo(code).then((res) => {
        if (res.status == 2000) this.openid = res.data.openid;
        else {
          this.msgDialog("系统错误");
        }
      });
    },
    postAddAccusation(params) {
      if (this.vCode != this.random) {
        this.msgDialog("验证码不正确");
        return;
      }

      postAddAccusation(params).then((res) => {
        if (res.status == 2000) this.$router.push("/success");
      });
    },
    msgDialog(msg) {
      this.msg = msg;
      this.msgShow = true;
    },
  },
  close() {},
  created() {
    var r1 = Math.floor(Math.random() * 10);
    var r2 = Math.floor(Math.random() * 10);
    this.random = r1 + r2;
    this.vCodeString = r1 + "+" + r2 + "=?";
  },
  mounted() {
    //this.getAccusationTarget();
    this.code = this.$route.query.code;
    //let local = window.location.href;
    //this.msgDialog(this.code);
    /*if (typeof this.code == "undefined" || this.code == null || this.code == "")
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7d0998d57ff5f7b2&redirect_uri=" +
        encodeURIComponent(local) +
        "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
    else this.getWxInfo(this.code);*/

    //this.type = this.$route.query.type;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.weui-label::before {
  content: "*";
  color: red;
}
.weui-select__files {
  list-style: none;
}
</style>
